import { styled } from '@creditas/stylitas'
import { getTypography, selectTheme, media } from '@creditas-ui/utilities'
import { Typography } from 'components/Typography'
import { styled as _styled } from '@creditas-ui/system'
import { getColorToken } from 'src/utils/getColorToken'

const Container = styled.div`
  display: flex;
  align-items: center;
  align-content: stratch;
  background: ${getColorToken('neutral.10')};
  margin: 48px auto;
  min-height: 484px;
  flex-direction: column-reverse;

  width: 100%;
  max-width: 318px;

  ${media.up('5xl')} {
    flex-direction: row;
    max-width: 392px;
    margin: 64px auto;
  }

  align-items: center;
`

const Content = styled.div`
  flex: 1;
  padding: 24px 24px 32px;

  ${media.up('5xl')} {
    padding: 48px 48px 56px;
  }

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  form {
    margin-top: 48px;
  }
`

const Header = styled.div`
  width: 100%;
`

const Title = _styled(Typography)`
  color: ${selectTheme('colors.neutral.90')};

  ${getTypography('headingXlLight')}
  
  strong {
    ${getTypography('headingXlBold')}
  }
`

const Description = _styled(Typography)`
    margin-top: 16px;
    color: ${selectTheme('colors.neutral.90')};
    ${getTypography('bodySmRegular')}

  `

export { Container, Content, Header, Title, Description }
