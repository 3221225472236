import { keyframes, styled } from '@creditas/stylitas'

const spin = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 8px;

    animation: ${spin} 1.1s infinite linear;
    animation-fill-mode: forwards;
  }
`

export { Container }
