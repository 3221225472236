import { Typography } from 'components/Typography'
import { styled } from '@creditas-ui/system'
import { getTypography, selectTheme } from '@creditas-ui/utilities'

const Container = styled.div`
  width: 100%;
`

const Title = styled(Typography)`
  color: ${selectTheme('colors.neutral.100')};
  ${getTypography('headingXlBold')}
`

const Subtitle = styled(Typography)`
  margin-top: 8px;
  ${getTypography('headingSmMedium')};
  color: ${selectTheme('colors.neutral.90')};
`

const Description = styled(Typography)`
  margin-top: 16px;
  margin-bottom: 40px;
  color: ${selectTheme('colors.neutral.100')};

  ${getTypography('bodySmMedium')};
`

export { Container, Title, Subtitle, Description }
