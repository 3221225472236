import { styled, mq } from '@creditas/stylitas'
import { getTypography, selectTheme, media } from '@creditas-ui/utilities'
import { styled as _styled } from '@creditas-ui/system'
import { ButtonCta } from 'components/ButtonCta'
import { WebCardBody } from 'components/Card'
import { Typography } from 'components/Typography'
import { fillMediaBase } from 'src/ui/utils/fillMediaBase'
import { getColorToken } from 'src/utils/getColorToken'

const Wrapper = styled('div')`
  position: relative;
  display: flex;
  min-height: 180px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: ${getColorToken('neutral.0')};
  padding: 24px;
  border-radius: 20px;
  box-shadow: 0 0 64px rgba(20, 20, 20, 0.04);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 24px;
    box-shadow: 0 28px 64px rgba(20, 20, 20, 0.08);
    z-index: -1;
  }

  ${mq({
    minHeight: fillMediaBase('152px', '180px'),
  })}
`

const Title = _styled(Typography)`
  color: ${selectTheme('colors.neutral.90')};
  margin-bottom: 12px;

  ${getTypography('bodyLgBold')};

  ${media.up('5xl')}{
    margin-bottom: 16px;
    ${getTypography('bodyXlBold')}
  }
`

const Body = _styled(WebCardBody)`
  white-space: pre-line;
  color: ${selectTheme('colors.neutral.60')};

  ${getTypography('bodySmRegular')};

  ${media.up('5xl')}{
    ${getTypography('bodyMdMedium')};
  }

  &:not(:last-child) {
    margin-bottom: 32px;
  }
`

const Cta = _styled(ButtonCta)`
    margin-top: auto;
    border: 1px solid transparent;
    text-decoration: none;
    color: ${selectTheme('colors.primary.60')};
    ${getTypography('bodyMdMedium')};

    &:hover {
      color:${selectTheme('colors.primary.60')};
      text-decoration: underline;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    svg {
      color: ${selectTheme('colors.primary.60')};
      display: block;
      margin-left: 8px;
    }
  `

export { Wrapper, Title, Body, Cta }
