import { styled } from '@creditas/stylitas'
import { styled as _styled } from '@creditas-ui/system'
import { getTypography, selectTheme, media } from '@creditas-ui/utilities'
import { Typography } from 'components/Typography'
import { getColorToken } from 'src/utils/getColorToken'

const Wrapper = styled.p`
  text-decoration: none;
  background-color: ${getColorToken('neutral.10')};
  margin-bottom: 32px;

  ${media.up('5xl')} {
    margin-bottom: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`

const Title = _styled(Typography)`
  padding-bottom: 8px;
  color: ${selectTheme('colors.primary.60')};
  ${getTypography('headingSmBold')};
  
  ${media.up('5xl')}{
    ${getTypography('headingLgLight')};
  }
`

const Description = _styled(Typography)`
  color: ${selectTheme('colors.neutral.80')};
  ${getTypography('bodyMdMedium')};
`

export { Wrapper, Title, Description }
