import { string } from 'prop-types'
import LoadingIcon from 'images/loading-icon.svg'
import { Container } from './styles'

const propTypes = {
  loadingMessage: string,
}

const defaultProps = {
  loadingMessage: 'Loading...',
}

const Spinner = ({ loadingMessage }) => {
  return (
    <Container>
      <img src={LoadingIcon} alt="Loading spinner" width={24} height={24} />
      {loadingMessage}
    </Container>
  )
}

Spinner.propTypes = propTypes
Spinner.defaultProps = defaultProps

export default Spinner
