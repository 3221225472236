import { styled } from '@creditas/stylitas'
import { getTypography, selectTheme, media } from '@creditas-ui/utilities'
import { Container as CreditasContainer, Grid } from '@creditas/layout'
import { Typography } from 'components/Typography'
import { styled as _styled } from '@creditas-ui/system'
import { getColorToken } from 'src/utils/getColorToken'

const Wrapper = styled.section`
  padding: 48px 0;
  background-color: ${getColorToken('neutral.10')};

  ${media.up('5xl')} {
    padding: 64px 0;
  }
`

const Container = styled(CreditasContainer)``

const Title = _styled(Typography)`
  margin-bottom: 8px;
  color: ${selectTheme('colors.neutral.90')};
  
  ${getTypography('headingMdLight')}

  ${media.up('5xl')}{
    ${getTypography('headingXlLight')}
  }
`

const SubTitle = _styled(Typography)`
  color: ${selectTheme('colors.neutral.80')};
  ${getTypography('bodyMdMedium')};
`

const Posts = styled(Grid)`
  margin-top: 24px;
`

const postsGridOptions = {
  gridPadding: ['0'],
  gridColumns: {
    xs: 'repeat(auto-fit, minmax(288px, 1fr))',
    md: 'repeat(4, 1fr)',
  },
}

export { Wrapper, Container, Title, SubTitle, Posts, postsGridOptions }
