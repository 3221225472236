import React from 'react'
import { string, object } from 'prop-types'
import { ArrowRight } from 'components/svgs'
import { Wrapper, Title, Body, Cta } from './customCard.style'

const propTypes = {
  title: string,
  description: string,
  buttonCta: object,
}

const CustomCard = ({ title, description, buttonCta, ...others }) => {
  const isValidButtonCta = buttonCta?.label && buttonCta?.actionHref
  const buttonCtaProps = {
    variant: 'internalLink',
    icon: ArrowRight,
    ...buttonCta,
  }
  const body = {
    childContentfulRichText: {
      html: description,
    },
  }

  return (
    <Wrapper {...others}>
      {title && <Title as="p">{title}</Title>}

      {description && <Body body={body} />}

      {isValidButtonCta && <Cta {...buttonCtaProps} />}
    </Wrapper>
  )
}

CustomCard.propTypes = propTypes

export { CustomCard }
