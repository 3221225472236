import { Typography } from 'components/Typography'
import { styled } from '@creditas-ui/system'
import { getTypography, selectTheme } from '@creditas-ui/utilities'

const Container = styled.div`
  width: 100%;
`

const Title = styled(Typography)`
  color: ${selectTheme('colors.neutral.90')};

  ${getTypography('headingXlLight')}

  strong {
    ${getTypography('headingXlBold')}
  }
`

const Description = styled(Typography)`
  margin-top: 12px;
  color: ${selectTheme('colors.neutral.100')};

  ${getTypography('bodyMdLight')};
`

export { Container, Title, Description }
