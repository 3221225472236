import React from 'react'
import Img from 'gatsby-image'
import { string, number, shape, array } from 'prop-types'

const propTypes = {
  src: string,
  srcWebp: string,
  srcSetWebp: string,
  sizes: string,
  alt: string,
  artDirection: array,
  aspectRatio: number,
  description: string,
  svg: shape({
    content: string,
  }),
}

const defaultProps = {
  sizes: '(max-width: 300px) 100vw, 300px',
  aspectRatio: 0.6,
}

const LazyImage = ({
  src,
  sizes,
  aspectRatio,
  srcWebp,
  srcSetWebp,
  svg,
  alt,
  artDirection,
  description,
  ...others
}) => {
  const fluidOptions = artDirection
    ? artDirection
    : {
        src,
        sizes,
        aspectRatio,
        srcWebp,
        srcSetWebp,
        srcSet: src,
      }

  if (artDirection) {
    description = artDirection.find(i => !!i.description)?.description
  }

  if (svg && svg.content) {
    return <div dangerouslySetInnerHTML={{ __html: svg.content }} />
  }

  return <Img fluid={fluidOptions} alt={description || alt} {...others} />
}

LazyImage.propTypes = propTypes
LazyImage.defaultProps = defaultProps

export { LazyImage }
