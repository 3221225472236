import { string } from 'prop-types'
import { Container, Title, Subtitle, Description } from './styles'

const propTypes = {
  message: string,
}

const defaultProps = {
  message: 'You can try again later',
}

const FinalErrorMessage = ({ message }) => {
  return (
    <Container data-testid="final-error">
      <Title variant="h2" as="p">
        Oops!
      </Title>
      <Subtitle variant="h5" as="p">
        Something went wrong.
      </Subtitle>
      <Description variant="support" color="bColorNeutral80">
        {message}
      </Description>
    </Container>
  )
}

FinalErrorMessage.propTypes = propTypes
FinalErrorMessage.defaultProps = defaultProps
export default FinalErrorMessage
