import { string, arrayOf, shape } from 'prop-types'
import {
  Wrapper,
  Container,
  Title,
  SubTitle,
  Posts,
  postsGridOptions,
} from './styles'
import Post from './Post'

const propTypes = {
  title: string,
  subTitle: string,
  posts: arrayOf(
    shape({
      title: string,
      description: string,
      slug: string,
    }),
  ),
}

const Highlights = ({ title, subTitle, posts }) => (
  <Wrapper>
    <Container maxWidth="lg">
      {title && <Title as="h2">{title}</Title>}
      {subTitle && <SubTitle>{subTitle}</SubTitle>}

      {posts?.length > 0 && (
        <Posts options={postsGridOptions}>
          {posts?.map(post => (
            <Post key={post.title} {...post} />
          ))}
        </Posts>
      )}
    </Container>
  </Wrapper>
)

Highlights.propTypes = propTypes

export default Highlights
