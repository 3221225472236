import { css, styled } from '@creditas/stylitas'

const Container = styled.div`
  width: 100%;
`

const StyledForm = styled.form(
  ({ shouldHideForm }) => css`
    width: 100%;
    .submitButton {
      margin-top: 8px;
    }

    ${shouldHideForm &&
      css`
        display: none;
      `}
  `,
)

export { Container, StyledForm }
