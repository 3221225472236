import React from 'react'
import { Typography } from 'components/Typography'
import { styled } from '@creditas/stylitas'
import { object } from 'prop-types'
import { svgToBackground } from 'ui/utils/svgToBackground'

const WebCardBodyStyled = styled(Typography)`
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  white-space: pre;

  p {
    margin-top: 8px;
  }

  b {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
  }

  ul {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
  }

  li {
    display: flex;
    align-items: center;

    p {
      margin-top: 0px;
      font-size: 16px;
    }
  }

  li:before {
    content: url(${({ svg }) => svgToBackground(svg)});
    padding-right: 5px;
    width: 40px;
    height: 40px;
  }
`

const propTypes = {
  body: object,
}

const WebCardBody = ({ body, ...others }) => (
  <WebCardBodyStyled
    as="div"
    {...others}
    dangerouslySetInnerHTML={{
      __html: body?.childContentfulRichText.html,
    }}
  />
)

WebCardBody.propTypes = propTypes

export { WebCardBody }
