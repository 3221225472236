import { string } from 'prop-types'
import { Container, Title, Description } from './styles'

const propTypes = {
  whyTitle: string,
  message: string,
}

const ThankYou = ({ whyTitle, message }) => {
  return (
    <Container data-testid="thank-you">
      <Title variant="h2" as="p">
        <strong>Thanks</strong> for {whyTitle}
      </Title>
      <Description>{message}</Description>
    </Container>
  )
}

ThankYou.propTypes = propTypes
export default ThankYou
