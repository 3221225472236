import { string } from 'prop-types'
import { Wrapper, Title, Description } from './post.styles'

const propTypes = {
  title: string,
  description: string,
}

const Post = ({ title, description }) => (
  <Wrapper>
    {title && <Title as="p">{title}</Title>}
    {description && <Description as="p">{description}</Description>}
  </Wrapper>
)

Post.propTypes = propTypes

export default Post
