import Form from 'components/Form'
import { useState } from 'react'

import { Container, Content, Header, Title, Description } from './styles'

const InvestorForm = ({ ...others }) => {
  const [isSuccess, setIsSuccess] = useState(false)
  const isSubmitting = false
  const hasSubmitted = false
  const finalMessage = ''

  const onSubmit = e => {
    e.preventDefault()

    setIsSuccess(true)

    const newTabForm = window.open()
    newTabForm.opener = null
    newTabForm.location =
      'https://cloud.comunicacao.creditas.com/investor_relations'
  }

  return (
    <Container id="investorForm" {...others}>
      <Content>
        {!isSubmitting && !hasSubmitted && (
          <Header data-testid="header">
            <Title variant="h3" as="p">
              <strong>Subscribe</strong> for
              <br /> updates
            </Title>
            <Description variant="support">
              Receive all our news in your email
            </Description>
          </Header>
        )}
        <Form
          submitText="Subscribe"
          onSubmit={onSubmit}
          isSubmitting={isSubmitting}
          success={isSuccess}
          message={finalMessage}
          successTitle="subscribing!"
        />
      </Content>
    </Container>
  )
}

export default InvestorForm
