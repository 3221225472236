import { styled } from '@creditas/stylitas'
import { ButtonPrimaryTextOnly as CreditasButton } from '@creditas-ui/button'
import { getColorToken } from 'src/utils/getColorToken'

export default styled(CreditasButton)`
  background: ${getColorToken('primary.40')};
  outline: none;
  font-size: 16px;
  line-height: 24px;
  color: ${getColorToken('neutral.90')};
  height: 60px;
  border-radius: 16px;

  span {
    font-weight: bold;
  }

  svg {
    fill: ${getColorToken('neutral.90')};
  }

  &:hover {
    background-color: ${getColorToken('primary.60')};
  }
`
