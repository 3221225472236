import { renderToStaticMarkup } from 'react-dom/server'

const removeEmotionStyle = svgString =>
  svgString.replace(/<style.*?\/style>/, '')
const svgToEncodedString = svgString => encodeURIComponent(svgString)
const svgToBackground = svg => {
  const svgString = removeEmotionStyle(renderToStaticMarkup(svg))

  return `data:image/svg+xml;utf8,${svgToEncodedString(svgString)}`
}

export { svgToBackground }
