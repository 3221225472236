import React from 'react'
import { string, oneOf, shape, object, arrayOf } from 'prop-types'
import { GridItem } from '@creditas/layout'
import { CustomCard } from 'components/Card/CustomCard'
import {
  Wrapper,
  Header,
  Title,
  Description,
  Image,
  CardsShelf,
  gridOptions,
  Grid,
} from './heroImage.style'

const propTypes = {
  title: string,
  headingType: oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  description: string,
  cards: arrayOf(
    shape({
      title: string,
      description: string,
    }),
  ),
  images: shape({
    desktop: object,
    mobile: object,
    description: string,
  }),
}

const defaultProps = {
  headingType: 'h1',
}

const HeroImage = ({
  title,
  headingType,
  description,
  images,
  cards,
  ...others
}) => {
  const heroImage = {
    artDirection: [
      {
        ...images?.desktop?.fluid,
        description: images?.description,
        media: '(min-width: 960px)',
        sizes: '(max-width: 1920px)',
      },
      {
        ...images?.mobile?.fluid,
        description: images?.description,
        sizes: '(max-width: 1024px)',
      },
    ],
  }

  return (
    <Wrapper maxWidth="lg" as="section" {...others}>
      <Grid options={gridOptions}>
        {(title || description) && (
          <Header area="header">
            {title && (
              <Title
                variant="h1"
                as={headingType}
                dangerouslySetInnerHTML={{
                  __html: title,
                }}
              />
            )}

            {description && <Description>{description}</Description>}
          </Header>
        )}

        {images && (
          <GridItem area="image">
            <Image {...heroImage} />
          </GridItem>
        )}

        {cards?.length && (
          <GridItem area="children">
            <CardsShelf>
              {cards?.map((card, index) =>
                card?.title ? (
                  <CustomCard
                    key={`CustomCard-${index}`}
                    {...card}
                    buttonCta={{
                      label: 'See More',
                      actionHref: card.slug,
                    }}
                  />
                ) : null,
              )}
            </CardsShelf>
          </GridItem>
        )}
      </Grid>
    </Wrapper>
  )
}

HeroImage.propTypes = propTypes
HeroImage.defaultProps = defaultProps

export { HeroImage }
